<route>
{
"name": "basic-info",
"meta": {
"title": "Filling basic info"
}
}
</route>

<template>
  <div>
    <filling-header icon="1" title="Basic info" step="1/3" :done="isStepDone" />

    <div class="form-label">First name:</div>
    <VeeFormError :error="errors.firstName">
      <ui-text-input v-model="values.firstName" :maxLength="64" placeholder="Ex: Joshua"></ui-text-input>
    </VeeFormError>

    <div class="form-label">Middle name:</div>
    <VeeFormError :error="errors.middleName" help="Leave blank if you don't have middle name">
      <ui-text-input v-model="values.middleName" :maxLength="64" placeholder="Ex: Baccay"></ui-text-input>
    </VeeFormError>

    <div class="form-label">Last name:</div>
    <VeeFormError :error="errors.lastName">
      <ui-text-input v-model="values.lastName" :maxLength="64" placeholder="Ex: Alonto"></ui-text-input>
    </VeeFormError>

    <div class="form-label">Date of Birth</div>
    <VeeFormError :error="errors.dateOfBirth">
      <ui-date-input
        v-model="values.student.dateOfBirth"
        :minDate="dateOfBirthMinDate"
        :maxDate="dateOfBirthMaxDate"
        @help="dateOfBirthHelp = $event"
      />
    </VeeFormError>

    <div class="basic-info-line"></div>

    <div class="form-label">Citizenship country:</div>
    <VeeFormError :error="errors.citizenshipCountryId">
      <ui-country
        v-model="values.student.citizenshipCountryId"
        placeholder="Enter your citizenship"
        @callingCodes="setCallingCodes"
      ></ui-country>
    </VeeFormError>

    <div class="form-label">Mobile number:</div>
    <VeeFormError :error="errors.phone" help="We'll use it to notify you about your application.">
      <ui-phone-input
        :callingCode="callingCode"
        v-model="values.student.phone"
        @valid="isPhoneValid = $event"
      ></ui-phone-input>
    </VeeFormError>

    <div class="basic-info-line"></div>

    <div class="form-label">Have you studied German language before?</div>
    <VeeFormError :error="errors.isStudyingGermanLanguage">
      <ui-radio-group v-model="isStudyingGermanLanguage" :options="yesno"></ui-radio-group>
    </VeeFormError>
    <slide-up-down :active="isStudyingGermanLanguage" :duration="500">
      <div class="form-label">Level of German:</div>
      <VeeFormError :error="errors.initialLanguageLevel" help="Self-estimated or certified" class="mt-8-force">
        <ui-radio-group :options="levelsOfGerman" v-model="values.student.initialLanguageLevel"></ui-radio-group>
      </VeeFormError>
    </slide-up-down>

    <filling-footer @next="handleSubmit" first_step></filling-footer>
  </div>
</template>

<script>
import FillingHeader from '../../components/filling/FillingHeader';
// import UiForm from '../../components/ui/UiForm';
// import UiFormItem from '../../components/ui/UiFormItem';
import UiTextInput from '../../components/ui/UiTextInput';
import UiRadioGroup from '../../components/ui/UiRadioGroup';
import UiPhoneInput from '../../components/ui/UiPhoneInput';
import FillingFooter from '../../components/filling/FillingFooter';
import SlideUpDown from '../../components/SlideUpDown';
import UiDateInput, { errorMessages as birthdayErrors } from '../../components/ui/UiDateInput';
import UiCountry from '../../components/ui/UiCountry';
import mapFields from '../../utils/mapFields';
import VeeFormValidation from '../../validator/vee-form-validation';
import VeeFormError from '../../validator/VeeFormError';

export default {
  components: {
    VeeFormError,
    UiCountry,
    UiDateInput,
    SlideUpDown,
    FillingFooter,
    UiPhoneInput,
    UiRadioGroup,
    UiTextInput,
    FillingHeader
  },
  data() {
    const dateOfBirthMinDate = new Date();
    dateOfBirthMinDate.setFullYear(dateOfBirthMinDate.getFullYear() - 100);

    const dateOfBirthMaxDate = new Date();
    dateOfBirthMaxDate.setFullYear(dateOfBirthMaxDate.getFullYear() - 18);

    return {
      errors: {},
      values: {
        firstName: '',
        middleName: undefined,
        lastName: '',
        student: {
          dateOfBirth: '',
          citizenshipCountryId: '',
          phone: '',
          initialLanguageLevel: undefined,
          timezoneOffset: new Date().getTimezoneOffset() / 60
        }
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'First name is required'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Last name is required'
          }
        ],
        citizenshipCountryId: [
          {
            required: true,
            message: 'Citizenship is required'
          }
        ],
        dateOfBirth: [
          {
            required: true,
            message: 'Please, select your date of Birth'
          },
          {
            validator: (value) => {
              if (value === '' && this.dateOfBirthHelp) {
                const { DATE_OUT_OF_MIN_BOUND, DATE_OUT_OF_MAX_BOUND } = birthdayErrors;

                if ([DATE_OUT_OF_MIN_BOUND, DATE_OUT_OF_MAX_BOUND].includes(this.dateOfBirthHelp)) {
                  return 'Your date should be between 18 and 100 years';
                }
                return this.dateOfBirthHelp;
              }
              return '';
            }
          }
        ],
        phone: [
          {
            validator: (value) => {
              if (!this.isPhoneValid) {
                if (value === '' || value === false) {
                  // console.log('phone vvv')
                  return 'Mobile number is required';
                }
                // console.log('phone vvv2')
                return 'Your mobile number is invalid. Please check it and try again';
              }
              return '';
            }
          }
        ],
        isStudyingGermanLanguage: [
          {
            validator: (value) => {
              if (value !== true && value !== false) {
                return 'Please answer this question';
              }
              return '';
            }
          }
        ],
        initialLanguageLevel: [
          {
            validator: (value) => {
              if (this.isStudyingGermanLanguage && !value) {
                // console.log('initialLanguageLevel invalid:', this.isStudyingGermanLanguage, value);
                return 'German language level is required';
              }
              return '';
            }
          }
        ]
      },

      yesno: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      levelsOfGerman: [
        { value: 'a1', label: 'A1' },
        { value: 'a2', label: 'A2' },
        { value: 'b1', label: 'B1' },
        { value: 'b2', label: 'B2' }
      ],

      callingCode: '',
      btnDisabled: true,
      dateOfBirthMinDate,
      dateOfBirthMaxDate,
      dateOfBirthHelp: '',
      isPhoneValid: false,
      isStudyingGermanLanguage: null,
      isStepDone: false
    };
  },
  computed: {
    ...mapFields({
      user: 'auth.user',
      student: 'auth.user.student',
      studentId: 'auth.user.student.id'
    }),
    flatValues() {
      return {
        ...this.values,
        ...this.values.student,
        isStudyingGermanLanguage: this.isStudyingGermanLanguage
      };
    }
  },
  created() {},
  watch: {
    values: {
      deep: true,
      handler() {
        this.validator.check(this.flatValues, this.rules);
      }
    }
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.validator.check(this.flatValues, this.rules, true);
      if (!isValid) return;

      try {
        const { student, ...user } = this.values;
        const [userData, studentData] = await Promise.all([
          this.$store.dispatch('users/patch', [this.user.id, user]),
          this.isStepDone
            ? this.$store.dispatch('students/patch', [this.user.student.id, student])
            : this.$store.dispatch('students/create', student)
        ]);

        this.user = {
          ...userData,
          student: studentData
        };

        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'application_finish_basic_info');
        this.$router.push({ path: '/filling/professional-details' });
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },
    setCallingCodes(callingCodes) {
      if (callingCodes && callingCodes.length) {
        // eslint-disable-next-line prefer-destructuring
        this.callingCode = callingCodes[0];
      }
    }
  },
  async mounted() {
    console.log(this.$store);
    try {
      const {
 firstName, middleName, lastName, gender, student 
} = this.$store.state.auth?.user;

      console.log('basic info preloaded:', gender);
      if (student) {
        this.values = {
          ...this.values,
          firstName,
          middleName,
          lastName,
          gender,
          student
        };
        if (this.values.student.initialLanguageLevel) {
          this.isStudyingGermanLanguage = true;
        } else {
          this.isStudyingGermanLanguage = false;
        }
        this.isStepDone = true;
      }
    } catch (err) {
      this.$notify.parseFeathersErrors(err);
    }

    this.validator = new VeeFormValidation(this.flatValues, this.rules, (key, message) => {
      // this.errors[key] = message
      this.$set(this.errors, key, message);
      // console.log('callback', key, message);
    });
  }
};
</script>

<style>
.form-label {
  color: #383838;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 2px;
}

.basic-info-line {
  height: 1px;
  background: #d9d9d9;
  margin: 16px 0;
}

.mt-8-force {
  margin-top: 8px !important;
}
</style>
