<template>
  <div class="ui-date-input ant-input ant-input-lg" :unused="validate" ref="wrap" @click.stop="focusToFirst">
    <div class="inputsContainer">
      <input
        ref="day"
        class="day"
        type="text"
        inputmode="numeric"
        placeholder="DD"
        maxlength="2"
        :value="day"
        @focus="selectAll"
        @blur="padDay"
        @input="setDay"
        @keydown="keydownDay"
      >
      <span class="dot" :class="{gray: day === ''}"></span>
      <input
        ref="month"
        class="month"
        type="text"
        inputmode="numeric"
        placeholder="MM"
        maxlength="2"
        :value="month"
        @focus="selectAll"
        @blur="padMonth"
        @input="setMonth"
        @keydown="keydownMonth"
      >
      <span class="dot" :class="{gray: month === ''}"></span>
      <input
        ref="year"
        class="year"
        type="text"
        inputmode="numeric"
        placeholder="YYYY"
        maxlength="4"
        :value="year"
        @focus="selectAll"
        @blur="padYear"
        @input="setYear"
        @keydown="keydownYear"
      >
    </div>
  </div>
</template>

<script>
  /* eslint-disable prefer-destructuring,radix */
  export const errorMessages = {
    DATE_OUT_OF_MIN_BOUND: 'Date is out of min bound',
    DATE_OUT_OF_MAX_BOUND: 'Date is out of max bound'
  }

  export default {
    name: 'UiDateInput',
    props: {
      value: {},
      minDate: {
        required: false,
        type: Date
      },
      maxDate: {
        required: false,
        type: Date
      }
    },
    data() {
      return {
        day: '',
        month: '',
        year: '',
        date: '',
        isChanged: false,
      }
    },
    computed: {
      validate() {
        const error = this.getError();
        if (!this.isChanged) {
          return '';
        }
        if (error) {
          this.$emit('help', error);
          this.$emit('input', '');
          this.$emit('change', '');
          return error;
        }
        const help = this.date.toUTCString().replace(/ \d+:\d+:\d+ GMT.*/i, '');
        this.$emit('help', help);
        this.$emit('input', this.date);
        this.$emit('change', this.date);
        return 'ok';
      },
    },
    methods: {
      focusToFirst(e) {
        // console.log('focusToFirst', e);
        if (e.target === this.$refs.wrap) {
          this.$refs.day.focus();
        }
      },
      getError() {
        const fields = [];
        if (this.day === '') fields.push('day');
        if (this.month === '') fields.push('month');
        if (this.year === '') fields.push('year');
        if (fields.length === 3) return 'Please, enter date';
        if (fields.length) return `Please, enter the ${fields.join(' and the ')} of date`;

        this.isChanged = true;

        this.date = new Date(Date.UTC(this.year, this.month - 1, this.day));
        if (this.date.getDate() !== parseInt(this.day)) return 'The date you\'ve entered is invalid';
        if (this.date.getMonth() + 1 !== parseInt(this.month)) return 'The date you\'ve entered is invalid';
        if (this.date < this.minDate) return errorMessages.DATE_OUT_OF_MIN_BOUND;
        if (this.date > this.maxDate) return errorMessages.DATE_OUT_OF_MAX_BOUND;
        // const y = parseInt(this.year);
        // if (y > new Date().getFullYear()) return 'The date you\'ve entered is in the future';
        // if (this.date.getFullYear() !== y) return 'The year you\'ve entered is invalid';
        // Must implement this eq as option only for birth date
        // if (y < 1920 || y > new Date().getFullYear() - 17) return 'Your date should be between 18 and 100 years';
        return '';
      },
      selectAll(e) {
        e.target.select()
      },
      padDay() {
        if (this.day.length === 1) {
          this.day = `0${this.day}`;
        }
        if (this.day !== '') {
          if (parseInt(this.day) < 1) this.day = '01';
          if (parseInt(this.day) > 31) this.day = '31';
        }
      },
      padMonth() {
        if (this.month.length === 1) {
          this.month = `0${this.month}`;
        }
        if (this.month !== '') {
          if (parseInt(this.month) < 1) this.month = '01';
          if (parseInt(this.month) > 12) this.month = '12';
        }
      },
      padYear() {
        if (this.year && this.year.length === 2) {
          const y = this.year;
          if (y > 50) this.year = `19${y}`;
          else this.year = `20${y}`;
        }
        // if (this.year !== '') {
        //   if (parseInt(this.year) < 1920) this.year = 1920;
        //   const currentYear = new Date().getFullYear();
        //   if (parseInt(this.year) > currentYear) this.year = currentYear;
        // }
      },
      setDay(e) {
        this.day = e.target.value;
        console.log('Date Input set day', e.target.value);
        if (this.day.length === 2) {
          this.$refs.month.focus();
        }
      },
      setMonth(e) {
        this.month = e.target.value;
        if (this.month.length === 2) {
          this.$refs.year.focus();
        }
      },
      setYear(e) {
        this.year = e.target.value;
        // console.log(e);
      },
      onlyDigits(e) {
        if (!/\d/.test(e.key) && e.key !== 'Enter' && e.key !== 'Backspace') {
          e.preventDefault();
        }
      },
      keydownDay(e) {
        this.onlyDigits(e);
        if (e.key === 'Enter') {
          this.$refs.month.focus();
        }
      },
      keydownMonth(e) {
        this.onlyDigits(e);
        if (e.key === 'Enter') {
          this.$refs.year.focus();
        }
        if (e.key === 'Backspace' && this.month === '') {
          e.preventDefault();
          this.$refs.day.focus();
        }
      },
      keydownYear(e) {
        this.onlyDigits(e);
        if (e.key === 'Backspace' && this.year === '') {
          e.preventDefault();
          this.$refs.month.focus();
        }
      },
      setDate(newValue) {
        if (!newValue) return;
        const date = new Date(newValue);
        const parts = date.toISOString().split('T')[0].split('-');
        const focused = document.querySelector(':focus');
        if (focused !== this.$refs.year) this.year = parts[0];
        if (focused !== this.$refs.month) this.month = parts[1];
        if (focused !== this.$refs.day) this.day = parts[2];
      }
    },
    watch: {
      value(newValue) {
        console.log('DateInput watch', newValue);
        this.setDate(newValue);
      }
    },
    mounted() {
      console.log('DateInput mounted start', this.value);
      this.setDate(this.value);
      console.log('DateInput mounted finish', this.value);
    }
  }
</script>

<style lang="scss">
  .ui-date-input {
    min-height: 48px;
    display: flex;
    align-items: center;

    .day, .month {
      width: 28px;
    }

    .year {
      width: 46px;
    }

    .day, .month, .year {
      border: 0px;
      text-align: center !important;
      outline: none;
      color: #383838;

      /* iphone fix */
      padding: 0px;
      margin:0px;
      border-radius: 0px;
    }

    .day:focus, .month:focus, .year:focus {
      border-bottom: 1px solid #383838;
    }

    .dot::before {
      content: '/';
    }

    .gray::before {
      color: #BFBFBF;
    }
  }
</style>
