<template>
  <div v-click-outside="clickOutside">
    <UiTextInput
      data-scrollanchorstart="ui-country-anchor"
      v-model="name"
      @focus="focus"
      @click="focus"
      @keyup="keyup"
      :placeholder="isHelperVisible ? 'Type text for search' : this.placeholder"
    />
    <AutoCompleteHelper ref="ach" :items="countries" :value="name" @select="emitIdByName" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';
import UiTextInput from './UiTextInput';
import AutoCompleteHelper from './AutoCompleteHelper';

export default {
  name: 'UiCountry',
  inheritAttrs: false,
  components: { AutoCompleteHelper, UiTextInput },
  directives: { ClickOutside },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: 'Select the country'
    }
  },
  data() {
    return {
      name: '',
      importantItems: ['Philippines', 'Saudi Arabia', 'United Arab Emirates', 'Kuwait', 'Singapore', 'Qatar'],
      isHelperVisible: false,
      isTouched: false
    };
  },
  computed: {
    ...mapState('countries', [
      'isFindPending'
    ]),
    ...mapGetters('countries', {
      data: 'list',
      countries: 'getNames',
      getById: 'get'
    })
  },
  watch: {
    isFindPending(value) {
      if (!this.name && !value) {
        this.setNameById(this.value);
      }
    },
    value(id) {
      this.setNameById(id);
    }
  },
  async created() {
    console.log('this.isFindPending: ', this.isFindPending)
    if (!this.isFindPending) {
      await this.findCountries({
        query: { $limit: 300 }
      });

      this.setNameById(this.value);
    }
  },
  methods: {
    ...mapActions('countries', {
      findCountries: 'find'
    }),
    keyup(e) {
      this.name = e.target.value;
      this.focus();
    },
    focus() {
      this.$refs.ach.show();
      this.isHelperVisible = true;
      this.isTouched = true;
    },
    clickOutside() {
      this.$refs.ach.hide();
      this.isHelperVisible = false;
      if (this.isTouched) {
        this.emitIdByName(this.name);
        this.isTouched = false;
      }
    },
    setNameById(id) {
      if (this.data.length) {
        console.log('display country by id:', id);
        const result = this.getById(id);
        if (result) {
          this.name = result.name;
          return;
        }
      }
      this.name = '';
    },
    emitIdByName(name) {
      console.log('emit id by name:', name);
      const result = this.data.find((item) => item.name === name);
      const id = result?.id;
      if (id !== undefined) {
        this.reset = false;
        this.$emit('input', id);
        this.$emit('change', id);
        this.$emit('callingCodes', result?.callingCodes);
        this.$emit('nameChanged', name);
      } else {
        this.$emit('input', null);
        this.$emit('change', null);
        this.$emit('nameChanged', name);
      }
    }
  }
};
</script>

<style scoped></style>
