<template>
  <div class="ui_phone_input">
    <input
      inputmode="tel"
      ref="phone_input"
      class="phone_input"
      :class="{isValid: isValid}"
      @input="handleInput"
    />
  </div>
</template>

<script>
  import intlTelInput from 'intl-tel-input';
  import 'intl-tel-input/build/js/utils.js';
  import 'intl-tel-input/build/css/intlTelInput.css';

  export default {
    name: 'UiPhoneInput',
    props: {
      value: {
        type: String
      },
      callingCode: {
        type: String,
      }
    },
    data() {
      return {
        iti: {},
        isValid: false,
        error: '',
      }
    },
    methods: {
      handleInput(event) {
        const { value } = event.target;
        const oldValid = this.isValid;
        this.isValid = this.iti.isValidNumber();
        // this.error = this.iti.getValidationError();

        const inValue = this.iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL);
        if (this.isValid && inValue !== value) {
          this.iti.setNumber(inValue);
        }

        this.$emit('input', inValue);
        this.$emit('valid', this.isValid);
        if (oldValid !== this.isValid) {
          this.$emit('change');
        }
      },
    },
    mounted() {
      this.iti = intlTelInput(this.$refs.phone_input, {
        formatOnDisplay: true,
        nationalMode: false,
        autoHideDialCode: false,
        autoPlaceholder: 'aggressive',
        preferredCountries: ['PH', 'SA', 'AE', 'KW', 'SG', 'QA']
      });
      if (this.value) {
        this.iti.setNumber(this.value);
        this.isValid = this.iti.isValidNumber();
        console.log(this.isValid);
        this.$emit('valid', this.isValid);
      }
    },
    watch: {
      value(newValue) {
        if (!newValue) return;
        this.iti.setNumber(newValue);
        this.isValid = this.iti.isValidNumber();
        this.$emit('valid', this.isValid);
      },
      callingCode(newValue) {
        if (!newValue) return;
        const inValue = this.iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL);
        if (inValue.length < 4) {
          this.iti.setNumber(`+${newValue}`);
          console.log(`Auto set phone country: +${newValue}`);
        }
      },
    }
  }
</script>

<style lang="scss">
  .has-error {
    .ui_phone_input {
      .phone_input {
        border-color: #f5222d;
      }
    }
  }

  .ui_phone_input {
    .iti {
      display: block;
      border-radius: 4px;
    }
    .iti__flag {
      background-image: url("~intl-tel-input/build/img/flags.png");
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .iti__flag {
        background-image: url("~intl-tel-input/build/img/flags@2x.png");
      }
    }
    .phone_input {
      display: block;
      width: 100%;
      height: 48px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
    }
    /*.phone_input.isValid {*/
      /*border: 1px solid #37d92f;*/
    /*}*/
  }
</style>
