<template>
  <div class="auto-complete-helper">
    <div class="icon"></div>
    <transition name="expand">
      <ul ref="ul" v-if="isShow">
        <li v-for="item in filteredItems" :key="item" @click="click(item)">{{item}}</li>
      </ul>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'AutoCompleteHelper',
    props: {
      items: {
        type: Array,
      },
      value: {
        type: String,
      },
      focused: {
        type: Boolean,
      }
    },
    data() {
      return {
        isShow: false,
      }
    },
    computed: {
      filteredItems() {
        let i = this.items;
        if (!i) i = [];
        return i.filter(
          (item) => item.toLowerCase().startsWith(this.value.toLowerCase())
        );
      },
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      click(item) {
        console.log('select', item);
        this.$emit('select', item);
        this.hide();
      },
    }
  }
</script>

<style lang="scss">
  .auto-complete-helper {
    position: relative;
    z-index: 10;
    ul {
      position: absolute;
      width: 100%;
      max-height: 200px;
      border: 1px solid #d9d9d9;
      background: #ffffff;
      box-shadow: 0 2px 21px 0 rgba(200, 200, 200, 0.6);
      border-radius: 4px;
      padding: 0px;
      overflow: auto;

      transform-origin: top;
      transition: all 0.3s ease-in-out;

      li {
        list-style: none;
        padding: 8px 11px;
      }

      li:hover {
        background: #aad6ff;
      }
    }

    .icon {
      width: 10px;
      height: 6px;
      position: absolute;
      top:-24px;
      right:10px;
      background-image: url("~@/assets/images/select_down_icon.svg");
      background-repeat: no-repeat;
    }

  }

  ul {
    cursor: pointer;
  }

  .expand-enter-active,
  .expand-leave-active {
    overflow: hidden;
  }

  .expand-enter-to,
  .expand-leave {
    opacity: 1;
    transform: scaleY(1);
  }

  .expand-enter,
  .expand-leave-to {
    opacity: 0;
    transform: scaleY(0);
  }
</style>
